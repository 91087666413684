.results {
    border: 1px solid #aaa;
    padding: 5px 0px;
    margin-top: 20px;
    min-height: 300px;
}

.results i {
    padding: 20px 10px;
}

.results button {
    white-space: normal;
    text-align: left;
}