body {
  margin: 0;
  font-family: Georgia, 'Bitstream Charter', serif;
  overflow-y: auto !important;
}

h2 {
  font-family: Georgia, 'Bitstream Charter', serif;
  font-size: 18px;
  font-weight: normal;
  text-align: left;
}

h1 {
  font-family: Georgia, 'Bitstream Charter', serif;
  font-size: 20px;
  font-weight: normal;
  padding-bottom: 3px;
  border-bottom: dotted 1px #aaaaaa;
}

h3, .volume-list-item {
  font-family: Georgia, 'Bitstream Charter', serif;
}

p {
  max-width: 800px;
  line-height: 1.5em;
  text-align: justify;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-table-cell {
  padding: 6px 10px !important;
}

.ant-descriptions-row button {
  height: inherit;
  padding-bottom: 0;
  padding-top: 0;
}