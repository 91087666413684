.sider > div > a, .sider .link {
  display: block;
  margin-bottom: 3px;
  font-family: 'Segoe UI', Tahoma, sans-serif;
  font-size: 14px;
}

.sider {
  padding-right: 30px;
}

.link span {
  float: right
}

.sider h3 {
  font-weight: normal;
  margin-bottom: 5px;
  font-size: 16px;
  border-top: 1px dotted #aaa;
  padding-top: 5px;
}

.sider h3:first-child {
  border-top: none;
}