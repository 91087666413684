.minus {
    padding: 0;
    position: absolute;
    right: 40px;
    top: 8px;
    height: 22px;
    width: 22px;
    color: rgba(0,0,0,0.45);
}

.container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}

.minimized {
    position: relative;
    height: 50px;
    background: white;

    font-size: 16px;
    font-weight: 600;
    padding: 20px 24px;
    color: rgba(0,0,0,0.8);
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    z-index: 10;
    margin: 0 auto;
}